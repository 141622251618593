/* You can add global styles to this file, and also import other style files */

:root {
  --black: #0000;
  --white: #fff;
  --yellow: #ffe599;

  --primary-color: #5192BC;
  --primary-color-light: #A9DDFF;
  --primary-color-light-2: #aac3d4;

  --secondary-color: #E9703D;
  --secondary-color-dark: #DD5F13;
  --secondary-color-light: #E95814;

  --tertiary-color: #DF2213;
  --tertiary-color-light: #f44336;

  --quaternary-color: #013188;
}
